import { ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

import { Type, Scope, AudienceLevel } from '@socrata/core-federations-api';

import * as actions from './actions';
import { ActionTypes } from './actions';

import { AddFederationModalScreen } from '../AddFederationModal';

/**
 * This "workflow state" is the overall state of the create federation modal
 * which uses the "withStepper" higher-order component.
 *
 * Each step in the stepper has access to and can update this state.
 */
export interface AddFederationState {
  /**
   * The type of federation that was selected.
   * This is done in the first step of the modal by the "FederationType" component.
   */
  type: Type;

  /**
   * The scope of the federation that was selected.
   * This is done in the first step of the modal by the "FederationType" component.
   */
  scope: Scope;

  /**
   * CNAME of the target domain to create the federation for.
   * This is done in the "CnameSearch" component and also validates that the federation relationship
   * is valid between the source (current) domain and proposed target.
   */
  validatedCname?: string;

  /**
   * Search boost to set for catalog federation domains.
   * This is chosen in the "FederationOptions" component, and only if the federation type is "catalog"
   */
  searchBoost?: string;

  /**
   * If this is present, it means that the button to initiate the federation has been clicked
   * BUT the initiation failed for some reason.
   */
  initiateFederationError?: string;

  /** Whether or not we're currently waiting on some async action to finish */
  busy: boolean;

  /**
   * The current modal screen being rendered
   */
  modalScreen: AddFederationModalScreen;

  /**
   * Audience level of assets to be included in the proposed federation relationship.
   * NOTE: this is also used as a translation key.
   */
  audienceLevel?: AudienceLevel;
}

export type Actions = ActionType<typeof actions>;

// @ts-ignore-error Ignore that state might be undefined
const reducer: Reducer<AddFederationState> = (
  state: AddFederationState,
  action: Actions
): AddFederationState => {
  switch (action.type) {
    case ActionTypes.SET_FEDERATION_TYPE:
      return {
        ...state,
        type: action.payload.type,
        scope: action.payload.scope
      };
    case ActionTypes.SET_MODAL_SCREEN:
      return {
        ...state,
        modalScreen: action.payload.modalScreen
      };
    case ActionTypes.SET_SEARCH_BOOST:
      return {
        ...state,
        searchBoost: action.payload.searchBoost
      };
    case ActionTypes.SET_TARGET_CNAME:
      return {
        ...state,
        validatedCname: action.payload.targetCname
      };
    case ActionTypes.SET_BUSY:
      return {
        ...state,
        busy: action.payload.busy
      };
    case ActionTypes.SET_AUDIENCE_LEVEL:
      return {
        ...state,
        audienceLevel: action.payload.audienceLevel
      };
    default:
      return state;
  }
};

export default reducer;
